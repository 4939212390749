import React from 'react';
import MediaAudioPlayer from '../components/MediaAudioPlayer';
import BannerInterview from '../components/BannerInterview';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import slugify from "slugify";

const BodyInterview = ({ interview, playingVideo, setPlayingVideo, playingAudio, setPlayingAudio }) => {

  const { name, position, positionDate, positionDetail, extract, quote, mediaVIDEO, mediaAUDIO } = interview;

  const transcriptHOST = 'https://educacionoh.udd.cl/transcripts/';
  const transcriptCOD = 'oh-mp-'
  const transcriptURL = transcriptHOST + transcriptCOD + slugify(name, { lower: true }) + '.pdf';

  return (
    <>
      <BannerInterview 
        interview={interview} 
        playingVideo={playingVideo} 
        setPlayingVideo={setPlayingVideo} 
        playingAudio={playingAudio} 
        setPlayingAudio={setPlayingAudio} 
        />
      <section className="pt-5">
        <div className="container">
          {
            mediaVIDEO.urlVideo ?
              <>
                <div className="row">
                  <div className="col-12">
                    <h1 className="mb-1">{name}</h1>
                    <p className="mb-1">{position}</p>
                    <p>{ positionDate }</p>
                    <p>{ positionDetail }</p>
                    <p>{ extract }</p>
                    <p>Escucha la entrevista completa</p>
                  </div>
                </div>

                <div className="row mt-3 align-items-end">
                  <div className="col">
                    <MediaAudioPlayer 
                      mediaAUDIO={mediaAUDIO} 
                      interview={interview} 
                      playingVideo={playingVideo} 
                      setPlayingVideo={setPlayingVideo} 
                      playingAudio={playingAudio} 
                      setPlayingAudio={setPlayingAudio}
                      />
                  </div>
                  <div className="col-12 col-md-3">
                    <a type="button"
                      href={transcriptURL}
                      target="_blank"
                      download
                      className="btn btn-primary w-100 btn-doc">
                      <FontAwesomeIcon icon={faFilePdf} /> Leer Transcripción
                    </a>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col">
                    <div className="quote-detail">
                      <div className="mark-l"></div>
                      <div className="mark-r"></div>
                      <p className="mb-0">{quote}</p>
                    </div>
                  </div>
                </div> */}
              </>
              :
              <>
                <div className="row mb-5">
                  <div className="col">
                    <div className="quote-detail">
                      <div className="mark-l"></div>
                      <div className="mark-r"></div>
                      <p className="mb-0">{quote}</p>
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col">
                    <p>{ positionDetail }</p>
                  </div>
                </div>
                <div className="row align-items-end">
                  <div className="col">
                    <MediaAudioPlayer 
                      mediaAUDIO={mediaAUDIO} 
                      playingVideo={playingVideo}
                      setPlayingVideo={setPlayingVideo}
                      playingAudio={playingAudio}
                      setPlayingAudio={setPlayingAudio}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <a type="button"
                      href={transcriptURL}
                      target="_blank"
                      download
                      className="btn btn-primary w-100 btn-doc">
                      <FontAwesomeIcon icon={faFilePdf} /> Leer Transcripción
                    </a>
                  </div>
                </div>
              </>
          }
        </div>
      </section>
    </>
  )
}

export default BodyInterview