import React, { useState, useRef, useEffect } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'

const MediaAudioPlayer = ({ mediaAUDIO, playingVideo, setPlayingVideo, playingAudio, setPlayingAudio }) => {

  const audioPlayer = useRef();
  const [audioPlaying, setAudioPlaying] = useState(playingAudio);
  const { urlAudio } = mediaAUDIO;

  useEffect(() => {
    
    if (playingAudio) {
      setAudioPlaying(true);
      audioPlayer.current.audio.current.play();
    } else {  
      setAudioPlaying(false);
      audioPlayer.current.audio.current.pause();
    }

  }, [playingAudio]);
  
  const handlePlayAudio = () => {

    if (playingVideo) {
      setPlayingVideo(false);
      setPlayingAudio(true);
      setAudioPlaying(true);
      audioPlayer.current.audio.current.play();
    } else {
      setPlayingAudio(true);
      setAudioPlaying(true);
      audioPlayer.current.audio.current.play();
    }
    
  }
  
  const handlePauseAudio = () => {
    audioPlayer.current.audio.current.pause();
    setPlayingAudio(false);
  }

  return (
    <>
      <AudioPlayer
          autoPlay={false}
          src={urlAudio}
          volume={1.0}
          layout="horizontal-reverse"
          showJumpControls={false}
          customVolumeControls={[]}
          customAdditionalControls={[]}
          ref={audioPlayer}
          customIcons={{
            play: <FontAwesomeIcon icon={faPlay} />,
            pause: <FontAwesomeIcon icon={faPause} />,
          }}
          onPlaying={() => handlePlayAudio()}
          onPlay={
            () => handlePlayAudio()
          }
          onPause={
            () => handlePauseAudio()
          }
        />
    </>
  )
}

export default MediaAudioPlayer