import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

import youtube from '../assets/svg/youtube-watermark.svg';

const MediaVideoPlayer = ({ url, curtain, muted = false, playingVideo = false, setPlayingVideo, playingAudio, setPlayingAudio }) => {

  const [playerVideo, setPlayerVideo] = useState({
    muted: muted,
    volume: 1,
    playerCurtain: curtain,
    controls: false,
    autoPlay: false,
    playing: playingVideo,
    seeking: false,
    played: 0,
    duration: 0,
    duration: 0,
    playedSeconds: '0:00',
    playedPercent: 0,
    playedSecondsTotal: 0,
    playedPercentTotal: 0,
  });

  useEffect(() => {
    setPlayerVideo({
      ...playerVideo,
      url: url,
      playing: playingVideo,
    });
  }, [url, playingVideo]);

  const { controls, playing, played, duration, playerCurtain, playedSeconds } = playerVideo;
  const playerRef = useRef(null);

  const handlePlayPause = () => {

    setPlayerVideo({
      ...playerVideo,
      playing: !playing,
    });
    setPlayingVideo(!playing);
    
    if ( playingAudio ) {
      setPlayingAudio(false);
    }

  }

  const handleDuration = (duration) => {
    setPlayerVideo({
      ...playerVideo,
      duration
    })
  }

  const handleProgress = (state) => {
    setPlayerVideo({
      ...playerVideo,
      playedSeconds: format(duration * played),
      played: state.played,
    });
  }

  const handleSeekMouseDown = e => {
    setPlayerVideo({
      ...playerVideo,
      seeking: true
    })
  }

  const handleSeekChange = e => {
    setPlayerVideo({
      ...playerVideo,
      played: parseFloat(e.target.value)
    })
  }

  const handleSeekMouseUp = e => {
    setPlayerVideo({
      ...playerVideo,
      seeking: false
    })
    playerRef.current.seekTo(parseFloat(e.target.value));
  }

  const handleEnded = () => {
    setPlayerVideo({
      ...playerVideo,
      playing: false,
      playedPercent: 0,
      playedSecondsTotal: 0,
      playedPercentTotal: 0,
    });
  }

  const format = (seconds) => {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
  }

  const pad = (string) => {
    return ('0' + string).slice(-2)
  }

  return (
    <>
      <div className="video-wrapper">
        <div className="video">

          <div className="banner-text">
            <img src={playerCurtain}
              className={`curtain-video ${playing ? 'hide' : ''}`}
              alt="cortina de video"
            />
            {
              !playing ?
              <a href="https://www.youtube.com/user/ViveUdd" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="youtube" /></a>
              :
              null
            }
            <button
              type="button"
              onClick={handlePlayPause}
              className={`${playing ? 'playing' : ''} btn btn-link text-white btn-player`}>
              {
                playing ?
                  <FontAwesomeIcon icon={faPause} size="5x" />
                  :
                  <FontAwesomeIcon icon={faPlay} size="5x" />
              }
            </button>
          </div>

          <ReactPlayer
            width="100%"
            height="100%"
            ref={playerRef}
            url={url}
            controls={controls}
            playing={playing}
            onDuration={handleDuration}
            onProgress={handleProgress}
            onEnded={handleEnded}
          />
        </div>
        <div className="player-control">
          <button
            className="btn btn-link"
            onClick={handlePlayPause}>
            {
              playing ?
                <FontAwesomeIcon icon={faPause} size="1x" />
                :
                <FontAwesomeIcon icon={faPlay} size="1x" />
            }
          </button>
          <div className="time-wrapper">
            {playedSeconds}
          </div>
          <div className="timer">
            <progress className="progress w-100" id="file" value={played} max="1"> </progress>
            <input
              className="progress-bar w-100"
              type='range' min={0} max={0.999999} step='any'
              value={played}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
            />
          </div>
          <div className="time-wrapper">
            {format(duration)}
          </div>
        </div>
      </div>
    </>
  )
}

export default MediaVideoPlayer
