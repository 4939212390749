import React, { useState } from 'react';
import slugify from "slugify";

import arrow from "../assets/svg/arrow.svg";
import MediaVideoPlayer from './MediaVideoPlayer';

const BannerInterview = ({ interview, playingVideo, setPlayingVideo, playingAudio, setPlayingAudio }) => {

  const { name, position, images, mediaVIDEO } = interview;
  const { img2, img3, img4 } = images;
  const { urlVideo } = mediaVIDEO;

  const slug = slugify(name, { lower: true, replacement: '_' });
  const assetsPATH = `../../images/${slug}/interior_entrevista/`;

  const mainIMG = `${assetsPATH}${img2}`;
  const thumbIMG1 = `${assetsPATH}${img3}`;
  const thumbIMG2 = `${assetsPATH}${img4}`;

  const mainFeatureIMG = mainIMG !== '' ? mainIMG : thumbIMG1;
  const [featureIMG, setFeatureIMG] = useState(mainFeatureIMG);

  const handleFeatureIMG = (img) => {
    setFeatureIMG(img);
  }

  const handlePlayPauseVideo = () => {
    // setPlayingAudio(!playingAudio);
    if (playingAudio) {
      setPlayingAudio(false);
      setPlayingVideo(!playingVideo);
    } else {
      setPlayingVideo(!playingVideo);
    }

  }

  return (
    <>
      {
        urlVideo !== ''
          ?
          (
            <div className="banner-page video">
              <MediaVideoPlayer 
                url={urlVideo}
                curtain={mainIMG}
                playingVideo={playingVideo}
                setPlayingVideo={setPlayingVideo}
                playingAudio={playingAudio} 
                setPlayingAudio={setPlayingAudio} 
                />
              {/* <div className="gradient"></div> */}
            </div>
          )
          :
          (
            <div className="banner-page interview">
              <img src={featureIMG} alt={name} />
              <div className="banner-info align-items-end">
                <div className="container h-100">
                  <div className="row h-100 align-items-end">
                    <div className="col">
                      {
                        img3 !== "" || img4 !== ""
                          ?
                          <div className="thumb-list">
                            <div className="thumb-item">
                              <img src={thumbIMG1} onClick={() => handleFeatureIMG(thumbIMG1)} alt="Miniatura" />
                            </div>
                            <div className="thumb-item">
                              <img src={thumbIMG2} onClick={() => handleFeatureIMG(thumbIMG2)} alt="Miniatura" />
                            </div>
                          </div>
                          :
                          null
                      }
                    </div>
                    <div className="col d-none d-md-inline-block"></div>
                    <div className="col-12 d-none d-md-inline-block col-md-4 position-relative text-end">
                      <img src={arrow} className="arrow animate__animated animate__bounce animate__delay-1s animate__slower animate__infinite" alt="arrow" />
                      <h1>{name}</h1>
                      <p className="mb-0">{position}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
      }

    </>
  )
}

export default BannerInterview