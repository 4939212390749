import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo";

import Card from "../components/Card";

import { graphql } from 'gatsby';

import BodyInterview from '../components/BodyInterview';

const InterviewPost = ({ pageContext }) => {

  const [playingVideo, setPlayingVideo] = useState(false);
  const [playingAudio, setPlayingAudio] = useState(false);
  const [histories, setHistories] = useState([])
  const { node, list } = pageContext;

  useEffect(() => {
    handleHistories();
  }, [])

  const getRandom = (arr, n) => {
    let result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      let x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  const filteredList = list.filter(item => item.id !== node.id);
  const moreHistories = getRandom(filteredList, 4);

  const handleHistories = () => {
    setHistories(moreHistories);
  }

  return (
    <Layout>
      <Seo title={'Entrevista'} />
      <BodyInterview
        interview={node}
        playingVideo={playingVideo}
        setPlayingVideo={setPlayingVideo}
        playingAudio={playingAudio}
        setPlayingAudio={setPlayingAudio}
      />

      <section className="py-5">
        <div className="container">

          <div className="row">
            <div className="col">
              <div className="interview-feature-list">
                <p>Ver más historias</p>
                <div className="card-list">
                  {histories.map((interviewed, index) => <Card key={index} interviewed={interviewed} />)}
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetInterviewPost($id: StringQueryOperatorInput) {
    allInterviewedDataJson(filter: {id: $id}) {
      edges {
        node {
          id
        }
      }
    }
  }
`

export default InterviewPost